.icon-n{
    margin: 52px -249px;
    /* padding: 14px 6px 11px; */
    min-width: 4px;
    width: 14px;
    height: 14px;
}
.icon-m1{
    margin: 51px -250px;
    /* padding: 14px 6px 11px; */
    min-width: 4px;
    width: 14px;
    height: 14px;
}
.icon-m2{
    margin: 52px -249px;
    /* padding: 14px 6px 11px; */
    min-width: 4px;
    width: 14px;
    height: 14px;
}