
/* .form-control
{padding: 20px;
font-size: 13px;
margin-bottom: 11px;
background: rgb(231 244 252);
border: 0;
border-radius: 10px;} */
.react-tel-input .form-control {
    padding: 20px !important;
    font-size: 13px !important;
    margin-bottom: 11px !important;
    background: rgb(231 244 252) !important;
    border: 0;
    border-radius: 10px;
    padding: 18.5px 14px 18.5px 60px !important;
    transition: box-shadow ease .25s,border-color ease .25s;
    color: #495057;
    width:100%
}