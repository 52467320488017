.login-popup{
    justify-content: space-between;
    margin: -9px 27px 7px 15px;
    display: flex;
}
.login-image{
    width:100%;
   
}

:where(.css-dev-only-do-not-override-18iikkb).ant-modal-root .ant-modal-wrap {
    position: fixed;
    inset: 0;
    z-index: 1000;
    overflow: hidden !important;
    outline: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    -webkit-overflow-scrolling: touch;
}
