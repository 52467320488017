@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600&display=swap');

/* @font-face {
  font-family: 'American-Typewriter';
  src: url('./american-typewriter.eot');
  src: url('american-typewriter.eot?#iefix') format('embedded-opentype'),
       url('american-typewriter.woff2') format('woff2'),
       url('american-typewriter.woff') format('woff'),
       url('') format('truetype'),
       url('american-typewriter.svg#american_typewriterregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

.American-Typewriter {
  font-family: 'American-Typewriter';
} */







:root {
  --main-color: #8e44ad;
  --black: #222;
  --white: #fff;
  --light-black: #777;
  --light-white: #fff9;
  --dark-bg: rgba(0, 0, 0, .7);
  --light-blue: #eee;
  --border: .1rem solid #bbb0b0;
  --box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
  --text-shadow: 0 1.5rem 3rem rgba(0, 0, 0, .3);
}

* {
  font-family: 'roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  /* text-transform: capitalize; */
}

.nav {
  border-bottom: 1px solid rgba(230, 230, 230, 1);
  background: rgba(230, 230, 230, 1);
  justify-content: space-between;
  align-items: center;
}

.nav__toggle {
  position: absolute;
  cursor: pointer;
  margin: 0rem 1rem;
  right: 0;
  top: 59px;

}

.close,
input[type="checkbox"] {
  display: none;
}

.hamburger {
  /* margin-top: 0.9rem; */
  position: relative;
  margin-top: -5.1rem;
  z-index: 99;
}

.nav__menu {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  margin: 0rem 0.4rem 0rem 3rem;
  padding: 12px 0px 12px 0px;

}

.nav__menu li .active {
  border-bottom: 2px solid #000;
  padding: 0px 8px 3px;

}

.nav__menu li {
  list-style: none;
  display: none;
}

/* .nav__menu li:first-child {
    margin-right: auto;
    display: block;
  } */
.nav__menu a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  padding: 0px 8px 3px;
  /* padding: 4px 0px 4px 0px; */
}

.nav__menu a:hover {
  background: rgba(246, 246, 246, 1);
  /* padding: 4px 0px 4px 0px; */
  /* border-bottom: 2px solid #000; */
  padding: 5px 8px 5px;
}

.nav__menu a:active {
  font-weight: 600;
  background: rgba(230, 230, 230, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 1);
  /* padding: 4px 0px 4px 0px; */
}

.head-btn {
  text-decoration: none;
  background: rgba(6, 40, 61, 1);
  color: #fff !important;
  border-radius: 5px;
  padding: 10px 24px 10px 24px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-bottom: unset !important;
}

.head-btn:hover {
  text-decoration: none;

  background: rgb(46 104 140) !important;
  color: #fff !important;
  border-radius: 5px;
  padding: 10px 24px 10px 24px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border-bottom: unset !important;
}



#nav__checkbox:checked~ul.nav__menu li {
  display: block;
}

#nav__checkbox:checked~label.nav__toggle .hamburger {
  display: none;
}

#nav__checkbox:checked~label.nav__toggle .close {
  display: block;
}

.logo-main {
  margin: 0rem 3rem 0rem 0.7rem;

}

.rims-log {
  width: 117px;
  height: auto;
}

.waveContainer {
  height: 100vh;
  overflow: hidden;
}

.viewBox {
  height: 100%;
  width: 100%;
}

.path {
  stroke: none;
  fill: rgb(237, 115, 115);
}



/*header*/

.sec-bg {
  background: #000;
  margin-top: 18px;

}

.title12 {
  margin-top: -40px;
  margin-bottom: 60px;
}

#box-second {
  /* margin-top: 388px!important; */
  background: rgba(0, 0, 0, 1) !important;
}

.container {
  width: 1170px;
}

.content-value {
  /* display: grid; */
  justify-content: center;
  position: relative;
}


.main-imgrims {
  width: 100%;
  height: auto;
  margin-top: 103px;
}

.nav-one {
  background: rgba(230, 230, 230, 1);
  position: fixed;
  z-index: 9;
  width: 100%;
}

.main-section {
  padding: 4rem 5%;
  background: #fff;
  margin-top: -1px;
}

.bg-img {
  background-image: url(../images/banner-rims.png);
  min-height: 558px;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 0px;
}

.bn1 {
  min-height: 558px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.46) 65.27%, rgba(0, 0, 0, 0.72) 72.8%, rgba(0, 0, 0, 0.86) 84.01%, #000 100%);
}

.form-contain {
  position: absolute;
  right: 0;
  margin: 120px 24px 83px;
  max-width: 300px;
  padding: 17px 23px 6px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 20px;
  width: 488px;
  height: 353px;
}

.form-contain h1 {
  font-weight: 400;
  font-size: 32px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}

.form-contain span {
  font-weight: 400;
  font-size: 32px;
  color: rgba(25, 167, 206, 1);
}

.form-contain p {
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 11px;
  text-align: center;
}

.form-group input {
  width: 100%;
  height: 31px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: transparent;
  margin: 5px 0px 3px;
  color: #fff;
  padding: 0px 24px 0px;
}

.input-icons img {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon-m {
  margin: 13px 4px;
  /* padding: 14px 6px 11px; */
  min-width: 4px;
  width: 14px;
  height: 14px;
}

.form-group .btn {
  background: rgba(37, 109, 133, 1);
  color: #fff;
  font-weight: 400;
  border: 1px solid rgba(37, 109, 133, 1);
  border-radius: 5px;
  padding: 5px 15px 5px;
}

.form-group .btn:hover {
  background: rgb(47 127 154);
}

.form-group .btn:focus {
  background: rgb(52, 134, 161);
}

.b-btn {
  text-align: right;
}

.text-banner {
  position: relative;
  top: -5%;
  bottom: 0%;
  /* left: 4%; */
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.text-banner h1 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin-left: -1px;
  font-family: 'Roboto', sans-serif;
}

.text-banner h1 span {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  margin-left: -1px;
  font-family: 'Roboto', sans-serif;
}

.img-btn {
  width: 100%;

}

.banner-name {
  margin-top: 19px;
}

.banner-name h6 {
  margin-top: 11px;
  color: rgba(0, 159, 227, 1);
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0px;
}

.banner-name p {
  color: rgba(0, 159, 227, 1);
  font-weight: 400;
  font-size: 18px;
}

.boxes {
  /* width:50%; */
  /* margin:0 auto; */
  justify-content: center;
  display: flex;
}

.boxes-d {
  /* width:50%; */
  /* margin:0 auto; */
  justify-content: center;
  display: flex;
}




.box {
  display: inline-block;
  width: 24%;
  /* height: 15em; */
  text-align: center;
  /* line-height: 15em; */
  color: #fff;
  margin: 3px 17px 3px 17px;
  border-radius: 14px;
  padding: 17px 15px 17px 15px;
}

.box-d {
  display: inline-block;
  width: 37%;
  /* height: 15em; */
  text-align: center;
  /* line-height: 15em; */
  color: #fff;
  margin: 3px 17px 3px 17px;
  border-radius: 14px;
  padding: 17px 15px 17px 15px;
}

.one-s {
  background: rgba(48, 1, 52, 1);
}

.two-s {
  background: rgba(0, 54, 77, 1);
}

.three-s {
  background: rgba(4, 36, 53, 1);
}

.four-s {
  background: rgba(87, 14, 30, 1);
}

.boxes h3 {
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  margin: 3px;
}

.boxes-d h3 {
  color: rgba(255, 255, 255, 1);
  font-weight: 600;
  margin: 3px;
  font-size: 20px;
  text-align: center;
}


.boxes-d span {
  font-size: 14px;
  font-weight: 500;
}

.boxes p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  margin: 1px 0px 13px;
}

.boxes-d p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  margin: 1px 0px 13px;
}

.img-app {
  display: flex;
  gap: 8px;

  justify-content: center;
}

.one-b {
  background: rgba(48, 1, 52, 1);
  display: flex;
  grid-gap: 11px;
  align-items: center;
}

.two-b {
  display: flex;
  background: rgba(0, 54, 77, 1);
  grid-gap: 11px;
  align-items: center;
}

.img-rims {
  width: 84px;
  height: auto;
}

.title-wrapper h2 {
  font-weight: 100;
  font-size: 50px;
  color: rgba(8, 70, 103, 1);
  text-align: left;
  margin-left: 0px;
}

.title-wrapper1 h2 {
  font-weight: 100;
  font-size: 50px;
  color: rgba(8, 70, 103, 1);
  text-align: left;
  margin-left: 57px;
}

.title-wrapper span {
  font-weight: 400;
  font-size: 50px;
  color: rgba(8, 70, 103, 1);
}

.title-wrapper1 span {
  font-weight: 400;
  font-size: 50px;
  color: rgba(8, 70, 103, 1);
}

.title-wrapper p {
  color: (0, 0, 0, 1);
  font-weight: 400;
  font-size: 14px;
}

.title-wrapper h5 {
  font-weight: 400;
  font-size: 24px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
}

.title-wrapper h6 {
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 1);
  text-align: left;
}

.client-rating {
  color: #ffb900;
}

.test-btn {
  color: rgba(0, 0, 0, 1);
  border: 1px solid rgba(0, 0, 0, 1);
  background-color: transparent;
  border-radius: 5px;
  padding: 8px 17px 8px;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 112px 26px;
  align-items: baseline;
  outline: unset;
}

.test-btn:hover {}

.mob-img {
  height: auto;
  width: 100%;
}

.md-pb {
  text-align: left;

}

.md-pb1 {
  text-align: right;

}


.just-content {
  text-align: justify;
}

.main-bg2 {
  /* background-image: url(../images/Frame2.png);
  width: 100%;
   height: 125vh;
    min-height: 766px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    align-items:center;
    display: flex;

    background-size: 100% 65%; */
  background-image: url(../images/Frame2.png);
  width: 100%;
  /* height: 125vh; */
  /* min-height: 766px; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  align-items: center;
  display: flex;
  background-size: 100% 100%;

}

.main-bg3 {
  background-image: url(../images/Frame3.png);
  width: 100%;
  /* height: 125vh;
    min-height: 743px; */

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  align-items: center;
  display: flex;

  background-size: 100% 100%;

}


.main-bg4 {
  background-image: url(../images/Frame4.png);
  width: 100%;
  /* height: 125vh;
    min-height: 743px; */

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  align-items: center;
  display: flex;

  background-size: 100% 100%;

}

.h2-b {
  margin-bottom: -91px;
  margin-left: 10px;

}

.h1-b {
  margin-left: -17px !important;
}

.h1-b1 {
  margin-bottom: 30px;
}

.hd1 {
  margin-top: 88px;
}

.right-cent {

  text-align: left;
  vertical-align: middle;
  align-items: center;
  display: flex;
  justify-content: right;

}

.center-content {
  vertical-align: middle;
  align-items: center;
  display: flex;
}

.video-one {
  width: 100%;
  height: 323px;
}



.uvp-list ul {
  display: inline-block;

  padding: 1rem 2rem;
  /* max-width: 320px; */
}

.uvp-list ul {
  list-style: none;
}

.uvp-list ul>li {
  clear: left;
  padding: .5rem 0;
}

.uvp-list ul>li:before {
  content: "";
  height: 1.5rem;
  width: 1.5rem;
  display: block;
  float: left;
  margin-left: -1.85rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.uvp-list ul>li:before {
  background: url("https://info.openpath.com/hubfs/checkmark-round-orange.svg");
  background-size: cover;
  background-position: center;
  padding: .15rem;

}



.title-wrapper h3 {
  color: rgba(6, 40, 61, 1);
  font-weight: 400;
  font-size: 24px;
  margin-right: -16px;
  margin-left: -14px;
}

.direct-dot {
  list-style: none;
}


.video-container {
  height: 280px;
  width: 294px;
  position: relative;
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
  border-radius: 8px;
}

.video-container-b video {
  width: 94%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
  border-radius: 8px;
}

.video-container2 {
  height: 280px;
  width: 177px;
  position: relative;
}


.breadcrumb-area1 {
  position: relative;
  background-attachment: scroll;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 226px 0 27px;
  z-index: 1;
  top: 46px;
  background-image: url("../../assests/images/about/rims-about-banner.png");
}

.breadcrumb-area1:before {
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .46) 65.27%, rgba(0, 0, 0, .72) 72.8%, rgba(0, 0, 0, .86) 84.01%, #000) !important;
}


.title h1 {
  color: #fff !important;
  line-height: 39px !important;
}

.frame-8562 {
  background: linear-gradient(to left, rgba(6, 40, 61, 0.84), rgba(6, 40, 61, 0.84)), linear-gradient(to left, #e0e0e0, #e0e0e0);
  border-radius: 8px;
  width: 177px;
  height: 254px;
  position: absolute;
  left: 1104px;
  top: 128px;
  overflow: hidden;
}


.name {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2em;
  line-height: 1;
  margin: 0 0 1.5em;
}

.name span {
  font-weight: 300;
}

.gallery-img {
  display: block;
  width: 100%;
}

.gallery {
  gap: 13px;
  width: 100%;
  height: auto;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}

.gallery-b {
  gap: 0px;
  width: 100%;
  height: auto;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}


.photo-container {
  margin-bottom: 3em;
  text-align: center;
  margin-left: .8em;
  margin-right: .8em;
}


.photo {
  position: relative;
  line-height: 0;
  overflow: hidden;
  margin-bottom: 2.5em;
}


.photo img {
  transform-origin: 0 0;
}

.photo:hover img {
  transform: scale(1.2);
}

/*About */

.title-about {
  margin-top: 50px;
}


.title-about h2 {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 32px;
}

.title-about h4 {
  color: rgba(64, 64, 64, 1);
  font-weight: 400;
  font-size: 28px;
  margin: 14px 0px 18px;

}

.title-about p {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 16px;
}

.circle {
  display: flex;
  align-items: center;
  gap: 41px;
}

.circle-1 {
  background: rgba(243, 200, 255, 1);
  width: 239px;
  height: 239px;
  border-radius: 50%;
  padding: 86px 53px 86px;
  font-size: 40px;
  font-weight: 400;
  color: rgba(64, 4, 81, 1);
}

.circle-2 {
  background: rgba(254, 247, 199, 1);
  width: 239px;
  height: 239px;
  border-radius: 50%;
  padding: 86px 53px 86px;
  font-size: 40px;
  font-weight: 400;
  color: rgba(58, 52, 9, 1);
}

.circle-3 {
  background: rgba(236, 255, 218, 1);
  width: 239px;
  height: 239px;
  border-radius: 50%;
  padding: 86px 41px 86px;
  font-size: 40px;
  font-weight: 400;
  color: rgba(49, 88, 11, 1);
}

.circle-s {
  color: rgba(0, 0, 0, 1);
  font-size: 28px;
  font-weight: 400;
  line-height: 35px;
}





.wrap {
  /* overflow: hidden; */
  margin: -148px;
  max-width: 1439px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 132px;
}

.box-l {
  float: left;
  position: relative;
  width: 24%;

  padding-bottom: 9%;

  align-items: center;
  display: flex;
}


.boxInner {
  position: absolute;
  overflow: hidden;
  margin-bottom: -10px;
}

.boxInner img {
  width: 100%
}



.-fx-image-gal {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
  justify-content: center;
}

.-fx-gal-item {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
}

.-fx-gal-item a {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: white;
}

.-fx-gal-image-thumb {
  margin: 12px;
  height: 227px;

}

.video-container-b {
  height: 215px;
  width: 294px;
  position: relative;
  margin-bottom: 23px;
}

/*features*/

.features-title {
  margin-right: 9px;
}

/* .features-title2 {
  margin-left: 61px;
} */
.features-title2 {
  margin-left: 20px;
}

.img-left {
  text-align: right;
}

.features-title h2 {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 32px;
  text-align: left;
  margin-left: 0px;
}

.features-title p {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 16px;
  text-align: right;
}

.features-title ul li {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 16px;
  text-align: left;
}


.features-title2 h2 {
  color: #000;
  font-size: 32px;
  font-weight: 400;
  margin-left: -15px;
  text-align: left;
  margin-top: 16px;



 
}

.features-title ul {
  margin-left: 15px;
}




.features-title2 p {
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 16px;
  text-align: left;
}

.content-top {
  margin-top: 56px;
  align-items: center;

}

/*footer*/


.footer-section {
  background: rgba(6, 40, 61, 1);
  position: relative;
}

.footer-cta {
  border-bottom: 1px solid rgba(255, 255, 255, 0.31);
}

.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #757575;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 200px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  line-height: 28px;
  font-weight: 400;
  line-height: 23px;

  width: 348px;

}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 40px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.facebook-bg {
  background: #3B5998;
}

.twitter-bg {
  background: #55ACEE;
}

.google-bg {
  background: #DD4B39;
}

.footer-widget-heading h3 {
  color: rgba(255, 255, 255, 0.48);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 24px;
  position: relative;

}

.footer-widget ul li {
  display: flex;
  /* float: left; */
  width: 50%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover {
  /* color: #ff5e14; */
  font-weight: 600;
  text-decoration: none;
}

.footer-widget ul li a {
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}

.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 13px 20px;
  border: 1px solid #ff5e14;
  top: 0;
}

.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  /* background: #202020; */
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}

.copyright-text p a {
  color: #ff5e14;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: #ff5e14;
}

.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

.foot-center {
  justify-content: center;
}

.foot-ic {
  display: flex;
  margin-bottom: 17px;
}

.foot-ic1 {
  padding-right: 21px;
}

.foot-ic a {
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 400;
}

.foot-last {
  justify-content: center;
}

.foot-add {
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 400;
}

.footer-social-icon {
  margin-bottom: 20px;

}




/*Contact */
.contact {
  padding: 100px 9px 80px;
}

.contact .heading h2 {
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  width: 222px;

}



.contact .heading p {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.7;
  color: #606060;
  margin: 20px 0 60px;
  padding: 0;
}

.contact .form-control {

  padding: 20px;
  font-size: 13px;
  margin-bottom: 11px;
  background: rgb(231 244 252);
  border: 0;
  border-radius: 10px;

}

.contact button.btn {
  /* padding: 7px; */
  border-radius: 10px;
  font-size: 15px;
  background: rgba(6, 40, 61, 1);
  color: #ffffff;
  font-weight: 400;
  height: 39px;

}

.contact button.btn:hover {
  background: rgb(46 104 140) !important;
  color: #ffffff;
  border: 2px solid rgb(46 104 140);
  font-weight: 400;
  /* padding: 7px; */
  border-radius: 10px;
  font-size: 15px;
  height: 39px;

}

.contact .title h3 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.contact .title p {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  line-height: 1.6;
  margin: 0 0 40px;
}

.contact .content .info {
  margin-top: 9px;

  display: flex;

}

.contact .content .info i {
  font-size: 30px;
  padding: 0;
  margin: 0;
  color: #ffffff;
  margin-right: 20px;
  text-align: center;
  width: 20px;
}

.contact .content .info h4 {
  font-size: 20px;
  line-height: 1.4;
  color: #fff;
  font-weight: 400;
}

.contact .content .info h4 span {
  font-size: 13px;
  font-weight: 300;
  color: #fff;
}

.address-side {
  background: rgba(6, 40, 61, 1);
  padding: 31px 26px 31px;
  /* max-width: 26.333333%; */
  border-radius: 10px;
  height: 35rem;
  margin-top: 13px;
}

.h1-c {

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  width: 168px;
  margin-bottom: 25px;

}

h2.h1-c:before,
.h1-b:after {
  background: #ffffff;
  height: 1px;
  flex: 1;
  content: '';
}

.h1-c span {
  font-weight: 400;
  /* background: #fff; */
  margin: 0 15px;
  color: #ffffff;
  font-size: 12px;
}

h2.h1-c:before {
  background: #ffffff;
  height: 1px;
  flex: 1;
  content: '';
}

.h1-c:after {
  background: unset;
}

.other-place {
  text-decoration: underline;
  font-weight: 500 !important;
  font-size: 14px !important;
}

.contact-form {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.14);
  padding: 47px 43px 115px;
  border-radius: 10px;

}

.contact-form {
  font-size: 14px;
  font-weight: 400;
  color: #5a5a5a;
  height: 35rem;
  margin-top: 13px;

}

@media(max-width:280px) {
  .title-about h2 {
    color: #000;
    font-size: 25px;
    font-weight: 400;
  }

  .title-about h4 {
    color: #404040;
    font-size: 22px;
    font-weight: 400;
    margin: 14px 0 18px;
  }
  .box-l {
    padding-bottom: 93%;
    width: 100%;
}
}

@media(max-width:400px) {
  
  .features-title2 h2 {
    color: #000;
    font-size: 19px;
    font-weight: 400;
    margin-left: -15px;
    text-align: left;
    margin-top: 14px;
}
  .img-app {
    display: flex;
    gap: 8px;
    justify-content: center;
    padding: 9px;
  }
}

@media (min-width: 420px) {
  .-fx-image-gal {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .-fx-gal-item {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .-fx-gal-item {
    width: 33.33%;
  }
}

@media only screen and (max-width:480px) {

  /* Smartphone view: 1 tile */
  .box-l {
    padding-bottom: 69%;
    width: 100%;
}
}
@media only screen and (max-width: 280px){
  .box-l {
      padding-bottom: 95%;
      width: 100%;
  }
}

@media only screen and (max-width:650px) and (min-width:481px) {

  /* Tablet view: 2 tiles */
  .box-l {
    width: 50%;
    padding-bottom: 50%;
  }
}

@media only screen and (max-width:1050px) and (min-width:651px) {

  /* Small desktop / ipad view: 3 tiles */
  .box-l {
    width: 33.3%;
    padding-bottom: 33.3%;
  }
}

@media only screen and (max-width:1290px) and (min-width:1051px) {

  /* Medium desktop: 4 tiles */
  .box-l {
    width: 25%;
    padding-bottom: 25%;
  }
}


@media (min-width: 1024px) {
  .gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;

  }

  .photo-container {
    margin-bottom: 3em;
    text-align: center;
    /* width: 25%;   */
    flex-grow: 1;
  }
}


@media (min-width: 1600px) {
  .photo-container {
    margin-bottom: 4em;
    width: 30%;
  }

  h2 {
    line-height: 1;
  }
}

@media (min-width:769px) and (max-width:1300px) {
  .img-app {
    display: flex;
    gap: 8px;
    justify-content: center;

  }
}


@media(max-width:530px) {
  .boxes-d {
    display: grid;
  }
}


@media (max-width: 1300px) {
  .text-banner h1 {
    color: #fff;
    font-size: 33px;
    font-weight: 400;
  }
}

@media (max-width: 1200px) {
  .text-banner h1 {
    color: #fff;
    font-size: 22px;
    font-weight: 400;
  }

  .banner-name h6 {
    margin-top: 11px;
    color: rgba(0, 159, 227, 1);
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 0px;
  }

  .banner-name p {
    color: rgba(0, 159, 227, 1);
    font-weight: 400;
    font-size: 16px;
  }

  .box-d {
    display: inline-block;
    width: 100%;
  }

  .text-banner h1 span {
    color: #fff;
    font-size: 21px;
    font-weight: 400;
    margin-left: -1px;
    font-family: 'Roboto', sans-serif;
  }
}

@media(max-width:1110px) {
  /* .features-title2 {
    margin-left: 51px;
  } */

  .text-banner {
    position: relative;
    top: 11%;
    bottom: 0%;
    /* left: 4%; */
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
}




@media only screen and (max-width: 990px) {
  .our-team {
    margin-bottom: 30px;
  }

  .h-blog-s {
    text-align: center;
    font-size: 4.3rem;
    font-weight: 500;
    margin-bottom: 2rem;
    padding: 0px 80px 0px;
  }
}



@media(max-width:900px) {
  .text-banner h1 {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
  }

  .text-banner {
    position: relative;
    top: -10%;
    bottom: 0%;
    left: 4%;
  }

  .form-contain {
    position: absolute;
    right: 0;
    margin: 135px 24px 83px;
    max-width: 300px;
    padding: 1px 23px 6px;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 20px;
    width: 488px;
    height: 342px;
  }

  .circle {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .circle-1 {
    background: rgba(243, 200, 255, 1);
    width: 199px;
    height: 199px;
    border-radius: 50%;
    padding: 72px 37px 69px;
  }

  .circle-2 {
    background: rgba(254, 247, 199, 1);
    width: 199px;
    height: 199px;
    border-radius: 50%;
    padding: 72px 37px 69px;
  }

  .circle-3 {
    background: rgba(236, 255, 218, 1);
    width: 199px;
    height: 199px;
    border-radius: 50%;
    padding: 72px 20px 69px;
  }
}

@media (max-width: 800px) {
  .text-banner h1 {
    width: 351px;
    color: #fff;
    font-size: 22px;
    font-weight: 400;
  }
}

@media(max-width:820px) {
  .banner-name {
    margin-top: 0px;
  }

  .container {
    width: unset;
  }

  .main-imgrims {
    width: 100%;
    height: auto;
    margin-top: 88px;
  }

  .box {
    display: inline-block;
    width: 45%;
    text-align: center;
    color: #fff;
    margin: 25px 17px 3px 17px;
    border-radius: 14px;
    padding: 17px 15px 17px 15px;
  }

  .box-d {
    display: inline-block;
    width: 45%;
    text-align: center;
    color: #fff;
    margin: 25px 17px 3px 17px;
    border-radius: 14px;
    padding: 17px 15px 17px 15px;
  }


  .boxes {
    /* width: 50%; */
    /* margin: 0 auto; */
    justify-content: center;
    display: inline-block;
  }

  .boxes {
    /* width: 50%; */
    /* margin: 0 auto; */
    justify-content: center;
    display: inline-block;
  }

  .top-img {
    order: 2;
  }

  .features-title h2 {
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-size: 32px;
    text-align: left;
    margin-left: 0px;
    margin-top: 18px;
  }
}



/* 768px */
@media screen and (max-width:48em) {
  .boxes {
    display: table;
    width: 100%;
  }

  .box {
    display: block;
    width: 100%;
  }

  .box-d {
    display: block;
    width: 100%;
  }

  .one {
    display: table-footer-group;
  }


}






@media only screen and (min-width: 768px) {

  .nav__toggle {
    display: none;
  }

  .nav__menu {
    flex-direction: row;
  }

  .nav__menu li {
    display: block;
  }


}


@media(max-width: 768px) {
  .md-pb {
    text-align: center;

  }

  .top-img {
    order: 2;


  }

  .text-banner h1 {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    margin-left: 5px;
  }

  .text-banner h1 span {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    margin-left: 5px;
  }

  .banner-name h6 {
    color: rgba(0, 159, 227, 1);
    font-weight: 700;
    font-size: 18px;
  }

  .banner-name p {
    color: rgba(0, 159, 227, 1);
    font-weight: 400;
    font-size: 16px;
  }

  .box {
    display: inline-block;
    width: 41%;
    text-align: center;
    color: #fff;
    margin: 25px 17px 3px 17px;
    border-radius: 14px;
    padding: 17px 15px 17px 15px;
  }

  .box-d {
    display: inline-block;
    width: 41%;
    text-align: center;
    color: #fff;
    margin: 25px 17px 3px 17px;
    border-radius: 14px;
    padding: 17px 15px 17px 15px;
  }

  .form-contain {
    margin: 657px 46px 83px;
  }

  #box-second {
    margin-top: -21px !important;
    background: #000 !important;
  }

  .circle {
    display: grid;
    align-items: center;
    gap: 10px;
    text-align: center;
    justify-content: center;
  }

  .feature-img1 {
    width: 100% !important;
  }

  .features-title p {
    display: table-cell;
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-size: 16px;
    text-align: left;
  }



  .features-title h2 {
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-size: 32px;
    text-align: left;
    margin-left: 0px;
    margin-top: 19px;
  }


  .box-d {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: #fff;
    margin: 25px 17px 3px 17px;
    border-radius: 14px;
    padding: 17px 15px 17px 15px;
  }

  .text-banner {
    position: relative;
    top: -10%;
    bottom: 0%;
    left: 4%;
    display: grid;
  }

  .footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
    line-height: 28px;
    font-weight: 400;
    line-height: 23px;
    width: 304px;
  }

}

@media(max-width:478px) {

  .boxes-d {
    justify-content: center;
    display: grid;
  }


  .box {
    display: inline-block;
    width: 93%;
    /* height: 15em; */
    text-align: center;
    /* line-height: 15em; */
    color: #fff;
    margin: 3px 17px 3px 17px;
    border-radius: 14px;
    padding: 17px 0px 17px 0px;

  }

  .box-d {
    display: inline-block;
    width: 93%;
    /* height: 15em; */
    text-align: center;
    /* line-height: 15em; */
    color: #fff;
    margin: 3px 17px 3px 17px;
    border-radius: 14px;
    padding: 17px 0px 17px 0px;

  }

  .nav {
    border-bottom: 1px solid rgba(230, 230, 230, 1);
    background: rgba(230, 230, 230, 1);
    justify-content: space-between;
    align-items: center;
    padding: 8px 6px 8px;
    margin: 0 79.4rem 0 1rem;
  }

  .title-wrapper h2 {
    font-weight: 100;
    font-size: 23px;
    color: rgba(8, 70, 103, 1);
    text-align: left;
  }

  .title-wrapper span {
    font-weight: 400;
    font-size: 29px;

    color: rgba(8, 70, 103, 1);
  }

}

@media (max-width: 400px) {
  .features-title h2 {
    color: rgba(0, 0, 0, 1);
    font-weight: 400;
    font-size: 19px;
    text-align: left;
    margin-left: 0px;
    margin-top: 14px;
  }


  .wrap-demo {
    margin: 10px;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 112px;
    padding-bottom: 397px;
  }

  .text-banner {
    position: relative;
    top: -10%;
    bottom: 0%;
    left: 4%;
    display: grid;
    grid-gap: 0px;
    height: 150px;
    justify-content: center;
  }

  .ceo-img {
    width: 43%;
  }

  .text-banner h1 {
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    margin-left: 7px;
    width: 228px;
  }

  .text-banner h1 span {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    margin-left: 7px;
    width: 228px;
  }

  .banner-name h6 {
    color: rgba(0, 159, 227, 1);
    font-weight: 700;
    font-size: 16px;
  }

  .banner-name p {
    color: rgba(0, 159, 227, 1);
    font-weight: 400;
    font-size: 15px;
  }

  .title-about h2 {
    color: #000;
    font-size: 24px;
    font-weight: 400;
  }

  .title-about h4 {
    color: #404040;
    font-size: 20px;
    font-weight: 400;
    margin: 14px 0 18px;
  }

  .title-about p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
  }
}

@media (max-width:280px) {
  .text-banner h1 {
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    margin-left: 5px;
    width: 190px;
  }

  .footer-text p {
    margin-bottom: 14px;
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
    line-height: 28px;
    font-weight: 400;
    line-height: 18px;
    width: 257px;
  }

  .title-about h2 {
    color: #000;
    font-size: 23px;
    font-weight: 400;
  }

  .title-about h4 {
    color: #404040;
    font-size: 18px;
    font-weight: 400;
    margin: 14px 0 18px;
  }

  .title-about p {
    color: #000;
    font-size: 13px;
    font-weight: 400;
  }

  .title h1 {
    color: #fff !important;
    line-height: 39px !important;
    font-size: 31px;
  }

  .circle-1,
  .circle-2 {
    border-radius: 50%;
    height: 190px;
    padding: 72px 37px 69px;
    width: 190px;
  }

  .circle-3 {
    background: #ECFFDA;
    border-radius: 50%;
    height: 190px;
    padding: 72px 20px 69px;
    width: 190px;
  }
}